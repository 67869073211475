import getConfigValue from '../../util/configuration.js';
import { SigninPopup } from './popup.js';

const Feide = {
    showFeideLogin: async function (emailHint) {
        const clientId = getConfigValue('feide-app-id');
        const redirect_uri = `${window.location.protocol}//${window.location.host}/oauth.html?provider=feide`;

        // First build the OAuth authorization URL
        const authUrl = 'https://auth.dataporten.no/oauth/authorization' +
            `?client_id=${encodeURIComponent(clientId)}` +
            `&redirect_uri=${encodeURIComponent(redirect_uri)}` +
            '&response_type=code' +
            '&scope=openid';

        // Then construct the discovery URL that wraps the auth URL
        const discoveryUrl = 'https://auth.dataporten.no/discovery' +
            `?returnTo=${encodeURIComponent(authUrl)}` +
            `&clientid=${encodeURIComponent(clientId)}`;

        return await (new SigninPopup(redirect_uri).open(discoveryUrl));
    }
};

export default Feide;
