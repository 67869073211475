<template>
    <section class="authentication-options">
        <button
            :class="option"
            @click="select_option(option)"
            v-for="{option, label} in options.primary"
            :key="option">
            <component v-bind:is="logo(option)" class="icon" />
            <span v-html="label"></span>
        </button>
        <button
            class="toggle-secondary"
            :class="{'open': secondary_options_open}"
            v-if="options.secondary.length > 0"
            @click="secondary_options_open = !secondary_options_open">
            <arrow-svg />
            <span v-html="more_options_label"></span>
            <arrow-svg />
        </button>
        <template v-if="secondary_options_open">
            <button
                :class="option"
                @click="select_option(option)"
                v-for="{option, label} in options.secondary"
                :key="option">
                <component v-bind:is="logo(option)" class="icon" />
                <span v-html="label"></span>
            </button>
        </template>
    </section>
</template>

<script>

import ArrowSvg from '../../../img/chevron-primary.svg'
import LoginButtonLogoApple from '../../../img/auth_provider/login_button_logo_apple.svg';
import LoginButtonLogoEmail from '../../../img/auth_provider/login_button_logo_email.svg';
import LoginButtonLogoFacebook from '../../../img/auth_provider/login_button_logo_facebook.svg';
import LoginButtonLogoGoogle from '../../../img/auth_provider/login_button_logo_google.svg';
import LoginButtonLogoWeibo from '../../../img/auth_provider/login_button_logo_weibo.svg';
import LoginButtonLogoFeide from '../../../img/auth_provider/login_button_logo_feide.svg';

export default {
    name: 'authentication-options',
    components: {
        LoginButtonLogoApple,
        LoginButtonLogoEmail,
        LoginButtonLogoFacebook,
        LoginButtonLogoGoogle,
        LoginButtonLogoWeibo,
        LoginButtonLogoFeide,
        ArrowSvg,
    },
    props: {
        options: {
            type: Object,
            required: true,
        },
        more_options_label: {
            type: String,
            required: true,
        }
    },
    data () {
        return {
            secondary_options_open: false,
        }
    },
    methods: {
        logo (option_name) {
            switch (option_name) {
                case 'apple':
                    return LoginButtonLogoApple;
                case 'email':
                    return LoginButtonLogoEmail;
                case 'facebook':
                    return LoginButtonLogoFacebook;
                case 'google':
                    return LoginButtonLogoGoogle;
                case 'weibo':
                    return LoginButtonLogoWeibo;
                case 'feide':
                    return LoginButtonLogoFeide;
            }
        },
        select_option (option) {
            this.$emit('option_selected', option)
        }
    }
};
</script>

<style scoped lang="scss">
@import '~Styles/colors';
@import '~Styles/mixins';

section.authentication-options {

    > button {
        margin: 0.5rem 0;
        width: 100%;
        font-weight: $font-bold;
        padding: 0 2rem;
        min-height: 2.5rem;
        height: auto;
        font-size: 1rem;
        line-height: 19px;
        border-radius: 0.5rem;

        background-color: white;
        color: $colorSourcePrimary;
        border: 1px solid $colorSourcePrimary;

        > svg.icon {
            height: 1.5rem;
            width: 1.5rem;
            position: absolute;
            left: 0.75rem;
            top: calc(50% - 12px);
            body[data-interface-language="ar"] & {
                left: unset;
                right: 0.75rem;
            }
        }

        &.toggle-secondary {
            background: initial;
            border: 1px solid transparent;

            &:focus {
                background-color: initial;
            }

            &:hover,
            &:active {
                background-color: $colorBackgroundLighter;
            }

            > svg {
                position: absolute;
                left: 0.75rem;
                height: 2rem;
                width: 2rem;
                top: calc(50% - 1rem);
                transition: transform 0.25s;

                &:nth-of-type(1) {
                    left: 0.5rem;
                }

                &:nth-of-type(2) {
                    left: initial;
                    right: 0.5rem;
                    transform: rotate(180deg);
                }
            }

            &.open {
                > svg {
                    &:nth-of-type(1) {
                        transform: rotate(90deg);
                    }

                    &:nth-of-type(2) {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        &:hover,
        &:focus {
            background-color: #EDEDED;
        }

        &:active {
            background-color: #D9D9D9;
        }

        &.apple {
            background-color: black;
            border-color: black;
            color: white;

            &:hover,
            &:focus {
                border-color: #202020;
                background: #202020;
            }

            &:active {
                border-color: #2D2D2D;
                background: #2D2D2D;
            }
        }

        &.email {
            background-color: $colorSourcePrimary;
            border-color: $colorSourcePrimary;
            color: white;

            &:hover,
            &:focus {
                background: #11274D;
                border-color: #11274D;
            }

            &:active {
                background: #0C1A31;
                border-color: #0C1A31;
            }

            &::v-deep > svg {
                top: calc(50% - 9px);

                > path {
                    fill: white;
                }
            }
        }

        &.google {
            background-color: $colorBackgroundLighter;
            border-color: transparent;
            color: $colorSourcePrimary;

            &:hover,
            &:focus {
                background: $colorBackgroundGray;
            }

            &:active {
                background: $colorBackgroundGray;
            }
        }

        &.facebook {
            background-color: $colorFacebookNew;
            border-color: $colorFacebookNew;
            color: white;

            &:hover,
            &:focus {
                background: $colorFacebookNewHover;
                border-color: $colorFacebookNewHover;
            }

            &:active {
                background: $colorFacebookNewActive;
                border-color: $colorFacebookNewActive;
            }
        }

        &.feide {
            background-color: #1F4698;
            border-color: #1F4698;
            color: white;

            &:hover,
            &:focus {
                background: darken(#1F4698, 5%);
                border-color: darken(#1F4698, 5%);
            }

            &:active {
                background: darken(#1F4698, 10%);
                border-color: darken(#1F4698, 10%);
            }
        }
    }
}
</style>
