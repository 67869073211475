var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    _vm._g(
      {
        attrs: {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      _vm.$listeners
    ),
    [
      _c("path", {
        attrs: {
          d: "M23 14h-3v4h3v-4zM2.015 24H23v-2.857h-9V16h-3v5.143H5V14H2l.015 10zM12.5 3.143a7.056 7.056 0 012.894.554c.92.388 1.754.966 2.455 1.7a7.739 7.739 0 011.622 2.571c.37.963.55 1.994.529 3.032h3c-.023-2.91-1.137-5.693-3.101-7.751C17.935 1.191 15.277.025 12.5 0 9.723.025 7.065 1.191 5.101 3.249 3.137 5.307 2.024 8.09 2 11h3a8.025 8.025 0 01.53-3.032A7.74 7.74 0 017.15 5.396a7.332 7.332 0 012.455-1.699 7.056 7.056 0 012.894-.554z",
          fill: "#fff",
        },
      }),
      _c("path", { attrs: { d: "M14 9h-3v3h3V9z", fill: "#fff" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }